import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import { MerchantMessageCard } from "./MerchantMessageCard";
import { NextStepsCard } from "./NextStepsCard";
import { PrivacyFooter } from "../../components/PrivacyFooter";
import { ReturnShoppingCard } from "./ReturnShoppingCard";
import { ReturnStatusCard } from "./ReturnStatusCard";
import { ReturnStatusErrorCard } from "./ReturnStatusErrorCard";
import { clearLoadingIndicator, showLoadingIndicator } from "../../redux/app/actions";
import { DropoffMethodIDs, ReturnShoppingItem } from "../../redux/customer/types";
import { Refund } from "../../types/Instance";
import { RootReducer } from "../../redux/reducers";
import { LabelType } from "../../types/LabelType";
import { ReturnStatus as ReturnStatusType } from "../../types/ReturnStatus";
import { isMobile } from "../../utility";
import useSelector from "../../utility/useTypedSelector";
import { ReturnStatus } from "../../types/ReturnStatus"

import {
  $ReturnStatus,
  $ReturnStatusCardContainer,
  $ReturnStatusLeftColumn,
  $ReturnStatusRightColumn,
  $ReturnStatusSingleColumn,
} from "./styles";
import { ProcessingFeeStatus } from "../Preview";
import { ReturnDetailsCards } from "./ReturnDetailsCards";
import { ReturnDetails } from "../../types/ReturnDetails";

export interface ReturnStatusComposite {
  deliveryDate?: string;
  departureDate?: string;
  dropoffMethodID?: DropoffMethodIDs;
  eatn?: string;
  emailAddress?: string;
  expiryDate?: string;
  expressCode?: string;
  isDraftOrderPaid?: boolean;
  numItemsInReturn?: number;
  otherDropoffMethodsAvailable?: boolean;
  merchantMessage?: string;
  retailerID?: string;
  retailerName?: string;
  returnID?: string;
  returnlessRefund?: boolean;
  returnShoppingDiscount?: string;
  returnShoppingInvoiceURL?: string;
  returnShoppingItems?: ReturnShoppingItem[];
  returnShoppingProcessingFeeWaived?: boolean;
  returnStatus?: ReturnStatusType;
  selectedCarrierName?: string;
  selectedLabelType?: LabelType;
  shipmentTrackingURL?: string;
  storeLocatorURL?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
  hasError?: boolean;
  returnDetails?: ReturnDetails[];
  previewInfoMessage?: string;
  processingFeeStatus?: string;
  isRetailerStoreApplePassEnabled?: boolean;
  locationBlockMilesThreshold?: string;
}

const showReturnShoppingCard = ({ returnShoppingItems, isDraftOrderPaid }: ReturnStatusComposite): boolean => {
  return !!returnShoppingItems && returnShoppingItems.length > 0 && !isDraftOrderPaid;
};

const showNextStepsCard = ({ returnStatus, returnlessRefund }: ReturnStatusComposite): boolean => {
  return (
    !!returnStatus &&
    (returnStatus == ReturnStatusType.started ||
      returnStatus == ReturnStatusType.droppedOff ||
      returnStatus == ReturnStatusType.expired ||
      (returnStatus == ReturnStatusType.complete && !!returnlessRefund))
  );
};

const showMerchantMessageCard = ({
  returnStatus,
  deliveryDate,
  departureDate,
  merchantMessage,
}: ReturnStatusComposite): boolean => {
  // merchantMessage !== " ", implements a workaround that is already in place when retailers don't want to display the default message.
  return (returnStatus === ReturnStatusType.started || returnStatus === ReturnStatusType.droppedOff) && !deliveryDate && !departureDate && !!merchantMessage && merchantMessage !== " ";
};

// For explicit layout desktop-mobile layout differences, use: `useViewport()` and `MOBILE_BREAKPOINT`. See
// `PrivacyFooter` component for usage example.

const renderDualColumnLayout = (returnStatusComposite: ReturnStatusComposite) => {
  return (
    <>
      <$ReturnStatusLeftColumn>
        <ReturnStatusCard {...returnStatusComposite} />
        {showNextStepsCard(returnStatusComposite) && <NextStepsCard {...returnStatusComposite} />}
        <ReturnDetailsCards {...returnStatusComposite}/>
      </$ReturnStatusLeftColumn>
      <$ReturnStatusRightColumn>
        {showReturnShoppingCard(returnStatusComposite) && <ReturnShoppingCard {...returnStatusComposite} />}
        {showMerchantMessageCard(returnStatusComposite) && <MerchantMessageCard {...returnStatusComposite} />}
      </$ReturnStatusRightColumn>
    </>
  );
};

const renderSingleColumnLayout = (returnStatusComposite: ReturnStatusComposite) => {
  return (
    <$ReturnStatusSingleColumn>
      <ReturnStatusCard {...returnStatusComposite} />
      {showNextStepsCard(returnStatusComposite) && <NextStepsCard {...returnStatusComposite} />}
      <ReturnDetailsCards {...returnStatusComposite}/>
    </$ReturnStatusSingleColumn>
  );
};

const renderDesktopReturnStatus = (returnStatusComposite: ReturnStatusComposite) => {
  if (showReturnShoppingCard(returnStatusComposite) || showMerchantMessageCard(returnStatusComposite)) {
    return renderDualColumnLayout(returnStatusComposite);
  }
  return renderSingleColumnLayout(returnStatusComposite);
};

const renderMobileReturnStatus = (returnStatusComposite: ReturnStatusComposite) => {
  return (
    <$ReturnStatusSingleColumn>
      <ReturnStatusCard {...returnStatusComposite} />
      {showNextStepsCard(returnStatusComposite) && <NextStepsCard {...returnStatusComposite} />}
      <ReturnDetailsCards {...returnStatusComposite}/>
      {showReturnShoppingCard(returnStatusComposite) && <ReturnShoppingCard {...returnStatusComposite} />}
      {showMerchantMessageCard(returnStatusComposite) && <MerchantMessageCard {...returnStatusComposite} />}
    </$ReturnStatusSingleColumn>
  );
};

const RenderReturnStatus = (returnStatusComposite: ReturnStatusComposite) => {
  if (isMobile()) {
    return renderMobileReturnStatus(returnStatusComposite);
  }
  return renderDesktopReturnStatus(returnStatusComposite);
};

const ReturnStatusPage = () => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { customer } = useSelector((store: RootReducer) => store);
  const { copies, links, locale } = useSelector((store) => store.app);
  const { returnStatusReturnID } = customer;

  //----------------------------------------------------------------------------

  const [returnStatusComposite, setReturnStatusComposite] = useState<ReturnStatusComposite>({

    // TODO clear default values
  //   deliveryDate: "",
  //   departureDate: "",
  //   dropoffMethodID: DropoffMethodIDs.returnBar,
  //   emailAddress: "hrtester@example.com",
  //   expiryDate: "2023-10-30 16:32:53.521736 +00:00",
  //   expressCode: "HRTESTER",
  //   merchantMessage:
  // "Items must be returned or exchanged in original condition and include the garment with all accessories, original garment tags and poly bag. Please refer to our [Return Policy](https://www.google.com) for all details.",
  //   // merchantMessage: "=| _-,;:!?/.'â€™\"()[]Â®@$Â£*&#%+â€¬â™¡ðŸ˜Š 0123456789aÃ¡bcdeÃ©fghijklmnoÃ³pqrstuÃºvwxyz-,zlynÃ¼Ã³Ã¤Ã wqifÃ¡vâ€™cesx\"pÃŸabdgÃ©ouÃ¨Ã´rjhÃ­k m.tæ˜¯ äº‹ ä¸– å¸‚ å¼? å£« å®¤ ç¤º è§† é‡Š ä¼¼ é€‚",
  //   // merchantMessage:
  //   //   "Items must be returned or exchanged in original condition and include the garment with all accessories, original garment tags and poly bag. Please refer to our [Return Policy](https://www.google.com) for all details.",
  //   isDraftOrderPaid: false,
  //   retailerName: "Happy Returns Flow",
  //   returnID: "83cd3a81-ec30-4747-aded-b869c90d9d6d",
  //   returnShoppingDiscount: "10",
  //   returnShoppingInvoiceURL: "https://www.google.com",
  //   returnShoppingItems: [
  //   {
  //     name: "Nomadic Coast Short",
  //     image:
  //       "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //     price: "50.00",
  //     quantity: 1,
  //     variantID: 39942123880514,
  //   },
  //   {
  //     name: "Nomadic Coast Short",
  //     image:
  //       "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //     price: "50.00",
  //     quantity: 1,
  //     variantID: 39942123880514,
  //   },
  //   {
  //     name: "Nomadic Coast Short",
  //     image:
  //       "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //     price: "50.00",
  //     quantity: 1,
  //     variantID: 39942123880514,
  //   },
  //   {
  //     name: "Nomadic Coast Short",
  //     image:
  //       "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //     price: "50.00",
  //     quantity: 1,
  //     variantID: 39942123880514,
  //   },
  // ],
  //   returnShoppingProcessingFeeWaived: true,
  //   returnStatus: ReturnStatusType.started,
  //   returnlessRefund: false,
  //   selectedCarrierName: "",
  //   returnDetails: [{
  //     subtotal: "TBD",
  //     total: "TBD",
  //     adjustments: [
  //       {
  //         "key":"Store Credit Bonus",
  //         "value": "NaN"
  //       }
  //     ],
  //     distributions: [
  //       {
  //         "key":"Store Credit",
  //         "value": "NaN"
  //       }
  //     ],
  //     instances: [
  //       {
  //         name: "Nomadic Coast Short",
  //         thumbnail:
  //           "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //         display: [
  //           {
  //             label: "Size",
  //             value: "small"
  //           },
  //           {
  //             label: "test-display",
  //             value: "test"
  //           }
  //         ],
  //         price: "25.00",
  //         id: "1",
  //       },
  //       {
  //         name: "Nomadic Coast Short",
  //         thumbnail:
  //           "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //         display: [
  //           {
  //             label: "Size",
  //             value: "small"
  //           },
  //           {
  //             label: "test-display",
  //             value: "test"
  //           }
  //         ],
  //         price: "25.00",
  //         id: "2",
  //       },
  //     ],
  //     exchanges: [
  //       {
  //         name: "Nomadic Coast Short",
  //         thumbnail:
  //           "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //         price: "50.00",
  //         display: [
  //           {
  //             label: "Size",
  //             value: "small"
  //           },
  //           {
  //             label: "test-display",
  //             value: "test"
  //           }
  //         ],
  //         id: "1",
  //       },
  //       {
  //         name: "Nomadic Coast Short",
  //         thumbnail:
  //           "https://s3-us-west-2.amazonaws.com/images.happyreturns.com/happy-returns-flow/resized/02fa9064a283af0352ca3bbac6242fa7.png",
  //         price: "50.00",
  //         display: [
  //           {
  //             label: "Size",
  //             value: "small"
  //           },
  //           {
  //             label: "test-display",
  //             value: "test"
  //           }
  //         ],
  //         id: "2",
  //       },
  //     ]
  //   }],
  //   previewInfoMessage: "test info message",
});
  const [returnStatusCompositeLoading, setReturnStatusCompositeLoading] = useState<boolean>(true);
  const [returnStatusCompositeError, setReturnStatusCompositeError] = useState<boolean>(false);

  useEffect(() => {
    const getReturnStatusComposite = async (returnID: string) => {
      try {
        dispatch(showLoadingIndicator(defaultLoadingSymbol));

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const returnStatusToken = params.get("authorization");

        let headers = {};
        if (!!returnStatusToken) {
          headers = {
            Authorization: `Bearer ${returnStatusToken}`,
          };
        }

        const response = await axios.get<ReturnStatusComposite>(`/returnstatus/${returnID}?locale=${locale}`, {
          headers: headers,
        });

        const returnStatusResponse = response?.data;

        if (returnStatusResponse.hasError) {
          setReturnStatusCompositeError(true);
        } else {
          let returnStatusComposite = response?.data;
          if (links && links["storeLocatorURL"]) {
            const storeLocatorURL = links["storeLocatorURL"].trim();
            returnStatusComposite = { ...returnStatusComposite, storeLocatorURL };
          }

          if (copies && copies.confirmationNextSteps) {
            returnStatusComposite = {
              ...returnStatusComposite,
              merchantMessage: copies.confirmationNextSteps,
            };
          }

          if (copies && copies.previewInfoMessage) {
            returnStatusComposite = {
              ...returnStatusComposite,
              previewInfoMessage: copies.previewInfoMessage
            }
          }

          // TODO uncomment this line
          setReturnStatusComposite(returnStatusComposite);
        }
      } catch (err) {
        console.error(err); // TODO CF-1202 Datadog logger
        setReturnStatusCompositeError(true);
      } finally {
        setReturnStatusCompositeLoading(false);
        dispatch(clearLoadingIndicator());
      }
    };

    getReturnStatusComposite(returnStatusReturnID);
  }, []);

  const startReturn = () => {
    // TODO CF-1202 Port current, necessary `ReturnStarted` logic
  };

  if (returnStatusCompositeLoading) {
    return (
      <$ReturnStatus>
        <PrivacyFooter />
      </$ReturnStatus>
    );
  }

  if (returnStatusCompositeError) {
    return (
      <$ReturnStatus>
        <$ReturnStatusCardContainer>
          <$ReturnStatusSingleColumn>
            <ReturnStatusErrorCard />
          </$ReturnStatusSingleColumn>
        </$ReturnStatusCardContainer>
        <PrivacyFooter />
      </$ReturnStatus>
    );
  }

  // Rendering `RenderReturnStatus` as JSX rather than invoking the function normally allows us to avoid
  // inlining functionality related to `isMobile()`
  return (
    <$ReturnStatus>
      <$ReturnStatusCardContainer>
        <RenderReturnStatus {...returnStatusComposite} />
      </$ReturnStatusCardContainer>
      <PrivacyFooter />
    </$ReturnStatus>
  );
};

export default ReturnStatusPage;
