// TODO break down, page-by-page
export enum DataCyStrings {
  orderNumberInput = "order-number-input",
  zipCodeInput = "zip-code-input",
  startWithAnEmailButton = "start-with-an-email-button",
  giftReturnButton = "gift-return-button",
  emailAddressInput = "email-address-input",
  notAGiftButton = "not-a-gift-button",
  startWithAnOrderIdButton = "start-with-an-order-id-button",
  headerTimeoutModal = "header-timeout-modal",
  messageTimeoutModal = "message-timeout-modal",
  orderNumber = "order-number",
  instanceButton = "instance-button",
  orderList = "order-list",
  exchangeCard = "exchange-button",
  suggestion = "suggestion",
  exchangePage = "exchange-page",
  exchangeOption = "exchange-option",
  exchangePageButton = "exchange-page-button",
  deselect = "deselect",
  nextStepButton = "next-step-button",
  showMoreOptions = "show-more-options",
  dropoffHeader = "dropoff-header",
  dropoffPrice = "dropoff-price",
  dropoffMethodText = "dropoff-method-text",
  returnPreviewCard = "return-preview-card",
  returnType = "return-type",
  exchangeRow = "exchange-row",
  itemDetails = "item-details",
  returningItemInfo = "returning-item-info",
  gettingItemInfo = "getting-item-info",
  subtotalAmount = "subtotal-amount",
  totalAmount = "total-amount",
  originalPaymentAmount = "original-payment-amount",
  submitReturnButton = "submit-return-button",
  expressCode = "express-code",
  qrCodeImage = "qrcode-image",
  seeAllLocationsLink = "see-all-locations-link",
  downloadShippingLabelButton = "download-shipping-label-button",
  downloadPackingSlipButton = "download-packing-slip-button",
  payInvoiceButton = "pay-invoice-button",
  reasonNoteTextArea = "reason-note-text-area",
  orderListContainer = "order-list-container",
  navigationCard = "navigation-card",
  loginPageErrorText = "login-page-error-text",
  loginPageUsernameInput = "login-page-username-input",
  loginPagePasswordInput = "login-page-password-input",
  loginPageSignInButton = "login-page-sign-in-button",
  loginPageAuthForm = "login-page-auth-form",
  loginPageSingleSignInButton = "login-page-single-sign-in-button",
  loginPageSSOLink = "login-page-sso-link",
  homePageNameDisplay = "home-page-name-display",
  homePageUserInfoDisplay = "home-page-user-info-display",
  homePageStartReturnButton = "home-page-start-return-button",
  orderListDisplay = "order-list-display",
  confirmationCodeInput = "confirmation-code-input",
  leftNavigationHeaderButton = "left-navigation-header-button",
  rightNavigationHeaderButton = "right-navigation-header-button",
  distributionRow = "distribution-row",
  scanBarcodeButton = "scan-barcode-button",
  scanToteCodeInput = "scan-tote-code-input",
  returnCountFooter = "return-count-footer",
  returnCount = "return-count",
  previewReturnButton = "preview-return-button",
  scanBagCodeInput = "scan-bag-code-input",
  finishReturnButton = "finish-return-button",
  confirmationPageTitle = "confirmation-page-title",
  appHeaderCenter = "app-header-center",
  confirmationWithoutLogisticsHeader = "confirmation-without-logistics-header",
  orderSearchInput = "order-search-input",
  returnCompleteHeader = "return-complete-header",
  newReturnNavigationCard = "new-return-navigation-card",
  giftReturnNavigationCard = "gift-return-navigation-card",
  returnTypeEmailInput = "return-type-email-input",
  languagePicker = "language-picker",
  globeIcon = "globe-icon",
  privacyPolicy = "privacy-policy",
  privacyPolicyFooter = "privacy-policy-footer",
  privacyPolicyFooterGerman = "privacy-policy-footer-german",
  receivedAGift = "received-a-gift",
  languageSelectionModal = "language-selection-modal",
  languageSelectionInputField = "language-selection-input-field",
  clearSearchIcon = "clear-search-icon",
  startYourReturnButton = "start-your-return-button",
  languageSelectionModalHeader = "language-selection-modal-header",
  noMatchFound = "no-match-found",
  returnReasonsModal = "return-reasons-modal",
  reasonNotesModal = "reason-notes-modal",
  reasonNotesNextButton = "reason-notes-next-button",
  returnReasonButton = "return-reason-button",
  middleTitle = "middle-title",
  returnOptionsModal = "return-options-modal",
  sustainableOptionsHeader = "sustainable-options-header",
  recommendedOptionsHeader = "recommended-options-header",
  seeHowLink = "see-how-link",
  termsAndConditionsDisclaimer = "terms-and-conditions-disclaimer",
  returnMethodFooterLabel = "return-method-footer-label",
  returningFor = "returning-for",
  optionDisabled = "option-disabled",
  closeModalButton = "close-modal-button",
  orderDate = "order-date",
  instanceListHeader = "instance-list-header",
  instanceListReminder = "instance-list-reminder",
  instanceListRetailerName = "instance-list-retailer-name",
  instanceListExpressCode = "instance-list-express-code",
  instanceItemStatus = "instance-item-status",
  instanceItemDetails = "instance-item-details",
  logo = "logo",
  itemName = "item-name",
  deselectSvgX = "deselect-svg-x",
  checkmarkSvg = "checkmark-svg",
  stepPrompt = "step-prompt",
  stepInstructions = "step-instructions",
  toggleEmail = "toggle-email",
  continueButton = "continue-button",
  scanAnotherBagButton = "scan-another-bag-button",
  bagBarcode = "bag-barcode",
  sendEmailButton = "send-email-button",
  alertMessage = "alert-message",
  successMessage = "success-message",
  neutralMessage = "neutral-message",
  infoMessage = "info-message",
  goToHomeScreenButton = "returnista-home-screen-button",
  previewPageHeader = "preview-page-header",
  partnerConfirmationTitle = "partner-confirmation-title",
  deleteBag = "delete-bag",
  storeNumberInput = "store-number-input",
  thankTheCustomerCard = "thank-the-customer-card",
  hrLogoIcon = "hr-logo-icon",
  bagItemsFinishReturnButton = "bag-items-finish-return-button",
  shirtIcon = "shirt-icon",
  rightArrowIcon = "right-arrow-icon",
  polybagIcon = "polybag-icon",
  toteIcon = "tote-icon",
  typeStoreNumberLink = "type-store-number-link",
  bagAllItemsAndScanCard = "bag-all-items-and-scan-card",
  seeExampleBagBarcodeLink = "see-example-bag-barcode-link",
  scanToteCard = "scan-tote-card",
  scanToteButton = "scan-tote-button",
  seeExampleToteBarcodeLink = "see-example-tote-barcode-link",
  bagToteBarcodePairButton = "bag-tote-barcode-pair-button",
  scanHistoryCard = "scan-history-card",
  deleteBagBarcodeButton = "delete-bag-barcode-button",
  cancelAndSeeScanHistoryLink = "cancel-and-see-scan-history-link",
  deleteBagToteBarcodeButton = "delete-bag-tote-barcode-button",
  returnOverrideModal = "return-override-modal",
  adminModeBadge = "admin-mode-badge",
  scanBarcodeExampleImage = "scan-barcode-example-image",
  itemSKU = "item-sku",
  itemPrice = "item-price",
  itemThumbnail = "item-thumbnail",
  decrementButton = "decrement-button",
  decrementIcon = "decrement-icon",
  incrementButton = "increment-button",
  incrementIcon = "increment-icon",
  countItemsPresentInput = "count-items-present-input-field",
  countItemsPresentSubmitButton = "count-items-present-submit-button",
  errorAlertBanner = "error-alert-banner",
  countItemsPresentModal = "count-items-present-modal",
  countItemsPresentModalHeader = "count-items-present-modal-header",
  primaryButton = "primary-button",
  leftNavigationHeaderShortButton = "left-navigation-header-short-button",
  emailNeededModal = "email-needed-modal",
  emailNeededModalTextInput = "email-needed-modal-text-input",
  emailNeededModalSubmitButton = "email-needed-modal-submit-button",
  countItemsPresentUpdateItemCount = "count-items-present-update-item-count",
  returnButton = "return-button",
  returnShoppingButton = "return-shopping-button",
  returnShoppingPreviewCard = "return-shopping-preview-card",
  showMoreLink = "show-more-link",
  returnShoppingWaiverText = "return-shopping-waiver-text",
  labelRequestInput = "label-request-input",
  labelRequestSubmitButton = "label-request-submit-button",
  inYourCartHeader = "in-your-cart-header",
  returnShoppingDiscount = "return-shopping-discount",
  returnShoppingImages = "return-shopping-images",
  moreIcon = "more",
  refundHeader = "refund-header",
  secondaryButton = "secondary-button",
  returnShoppingContainer = "return-shopping-container",
  returnShoppingReturnSubmittedContainer = "return-shopping-return-submitted-container",
  returnShoppingImageContainer = "return-shopping-image-container",
  pickupDate = "pickup-date",
  downloadAgainButton = "download-again-button",
  errorMessage = "error-message",
  storeCreditButton = "store-credit-button",
  onlySomeImagesArePartOfReturn = "only-some-images-are-part-of-return",
  confirmationCheckMark = "confirmation-check-mark",
  downloadQRCodeLink = "download-qr-code-link",
  errorIcon = "error-icon",
  submitNewReturnButton = "submit-new-return-button",
  redesignUsernameInput = "login-page-username-input",
  redesignPasswordInput = "login-page-password-input",
  returnExpirationMessage = "return-expiration-message",
  location = "location",
  orderNumberMessage = "order-number-message",
  retailerMessage = "retailer-message",
  itemListMessage = "item-list-message",
  confirmationNextSteps = "confirmation-next-steps",
  bagCountInput = "bag-count-input",
  printAgain = "print-again",
  nextStepsAppleWalletButton = "next-steps-apple-wallet-button",
  nextStepsBodyDelivered = "next-steps-body-delivered",
  nextStepsBodyDeparted = "next-steps-body-departed",
  nextStepsBodyExpired = "next-steps-body-expired",
  nextStepsBodyStartedShoes = "next-steps-body-started-shoes",
  nextStepsBodyStartedItemBarcodes = "next-steps-body-started-item-barcodes",
  nextStepsBodyStartedPackingSlip = "next-steps-body-started-packing-slip",
  nextStepsBodyStartedMailNoLabel= "next-steps-body-started-mail-no-label",
  nextStepsBodyStartedQRCode = "next-steps-body-started-QR-code",
  nextStepsBodyStartedShippingLabel = "next-steps-body-started-shipping-label",
  nextStepsBodyStartedShippingMaterials = "next-steps-body-started-shipping-materials",
  nextStepsBodyStartedLocationMilesThreshold = "next-steps-body-started-location-miles-threshold",
  nextStepsBodyReturnless = "next-steps-body-delivered",
  nextStepsButtonFindLocation = "next-steps-button-find-location",
  nextStepsButtonFindReturnBar = "next-steps-button-find-return-bar",
  nextStepsButtonFindUPSStores = "next-steps-button-find-ups-stores",
  nextStepsButtonStartNewReturn = "next-steps-button-start-new-return",
  nextStepsButtonTrackReturn = "next-steps-button-track-return",
  nextStepsButtonPackingSlip = "next-steps-button-packing-slip",
  nextStepsButtonShippingLabel = "next-steps-button-shipping-label",
  nextStepsConfirmationDetailsHeader = "next-steps-confirmation-details-header",
  nextStepsConfirmationDetailsResendEmailLink = "next-steps-confirmation-details-resend-email-link",
  nextStepsHeaderDelivered = "next-steps-header-delivered",
  nextStepsHeaderDeparted = "next-steps-header-departed",
  nextStepsHeaderExpired = "next-steps-header-expired",
  nextStepsHeaderReturnless = "next-steps-header-returnless",
  nextStepsHeaderStartedInStore = "next-steps-header-in-store",
  nextStepsHeaderStartedMail = "next-steps-header-mail",
  nextStepsHeaderStartedMailNoBoxNoLabel = "next-steps-header-mail-nobox-nolabel",
  nextStepsHeaderStartedMailNoLabel = "next-steps-header-mail-nolabel",
  nextStepsHeaderStartedReturnBar = "next-steps-header-return-bar",
  nextStepsQRCard = "next-steps-QR-card",
  nextStepsQRCarrierText = "next-steps-qr-carrier-text",
  nextStepsQRCodeImageExpressCode = "next-steps-qr-code-image-express-code",
  nextStepsQRCodeImageMailLabel = "next-steps-qr-code-image-express-code-mail-label",
  nextStepsStartedUpsBanner = "next-steps-started-ups-banner",
  nextStepsQRCodeText = "next-steps-qr-code-text",
  requestSupport = "request-support",
  confirmRequestSupportButton = "confirm-request-support-button",
  cancelRequestSupportButton = "cancel-request-support-button",
  requestSupportSuccessMessage = "request-support-success-message",
  requestSupportErrorMessage = "request-support-error-message",
  requestOutOfBags = "request-out-of-bags",
  cancelOutOfBagsButton = "cancel-out-of-bags-button",
  confirmOutOfBagsButton = "confirm-out-of-bags-button",
  tryAgainOutOfBagsButton = "try-again-out-of-bags-button",
  returnStatusHeader = "return-status-header",
  returnStatusExpressCode = "return-status-express-code",
  returnStatusStatusBar = "return-status-status-bar",
  itemDetailsContainer = "item-details-container",
  gettingItemDetails = "getting-item-details",
  refundSummary = "refund-summary",
  inYourReturnItemList = "in-your-return-item-list",
  inYourReturnHeader = "in-your-return-header",
  gettingItemList = "getting-item-list",
  returnDetailsInfoMessage = "return-details-info-message",
  returnShoppingInfoMessage = "return-shopping-info-message",
  gettingDiv = "getting-div",
  moreLessButtonGetting = "more-less-button-getting",
  moreLessButtonInYourReturn = "more-less-button-in-your-return",
}
