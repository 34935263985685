import React, { useEffect, useState } from "react";

import {
  $GettingDiv,
  $GettingHeader,
  $HeaderText,
  $InfoMessageContainer,
  $InYourReturnDiv,
  $InYourReturnHeader,
  $ItemDetailsContainer,
  $ReturnDetailsCard,
  $ReturnStatusItemDetails,
  $ShowMoreLessText,
} from "./styles";
import { ReturnStatusComposite } from "../../index";
import { ReturnStatus } from "../../../../types/ReturnStatus";
import { RefundSummary } from "./RefundSummary";
import { SVG } from "../../../../components/svg";
import { InfoMessage } from "../../../../components/AlertMessage";
import getTranslator from "../../../../utility/getTranslator";
import { AppRuntimes } from "../../../../types/AppRuntimes";
import ga from "../../../../utility/GAEmitter";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  ReturnStatusPageActions
} from "../../../../types/Analytics";
import { DataCyStrings } from "../../../../types/DataCyStrings";
import ItemDetails from "../../../../components/ItemDetails";
import {
  checkIfItemHasValidPrice,
  containsDigit,
  getAdminMode,
  handleEnterKeyPressOnClickHandlers
} from "../../../../utility";
import { $ScreenReaderOnlyComponent } from "../../../ScreenReaderOnlyComponent/styles";

interface ReturnDetailsCardProps extends ReturnStatusComposite {
  index?: number;
}

interface ItemListSectionProps extends ReturnDetailsCardProps {
  isGettingSection?: boolean;
}

export const ReturnDetailsCard = (returnDetails: ReturnDetailsCardProps) => {
  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: getAdminMode(),
        return_status: returnDetails.returnStatus,
      },
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnStatus, AnalyticCategories.ReturnStatusPage);
  }, []);

  return (
    <$ReturnDetailsCard>
      <ReturnDetailsInfoMessage {...returnDetails}/>
      <InYourReturnSection {...returnDetails}/>
      <GettingSection {...returnDetails}/>
    </$ReturnDetailsCard>
  );
};

const getInYourReturnSectionHeaderText = (returnDetails: ReturnDetailsCardProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();

  if (returnDetails.returnDetails && returnDetails.index !== undefined && returnDetails.returnDetails[returnDetails.index].refundedAt) {
    const date = new Date(returnDetails.returnDetails[returnDetails.index].refundedAt);
    return t("approvedAtDate", {
      month: t(`month.${date.getMonth()}`),
      day: date.getDate(),
      year: date.getFullYear(),
    });
  }

  return t("itemsInYourReturn")
}

const InYourReturnSection = (returnDetails: ReturnDetailsCardProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();
  const [isInYourReturnExpanded, setIsInYourReturnExpanded] = useState(true);

  const itemListProps = {
    isGettingSection: false,
    ... returnDetails
  }

  const onShowMoreLessClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStatusPageActions.returnDetailsInYourReturnSectionShowMoreLessClicked
    })
  }

  return (
    <$InYourReturnDiv>
      <$InYourReturnHeader>
        <$HeaderText data-cy={DataCyStrings.inYourReturnHeader}>
          <h2 className="itemsInYourReturn">{getInYourReturnSectionHeaderText(returnDetails)}</h2>
        </$HeaderText>
        <$ShowMoreLessText {...handleEnterKeyPressOnClickHandlers(() => {
                              setIsInYourReturnExpanded(!isInYourReturnExpanded);
                              onShowMoreLessClick();
                           })}
                           data-cy={DataCyStrings.moreLessButtonInYourReturn}
        >
          {isInYourReturnExpanded ? t("showLess") : t("showMore")}
          <SVG name={isInYourReturnExpanded ? "up" : "down"} />
        </$ShowMoreLessText>
      </$InYourReturnHeader>
      {
        isInYourReturnExpanded && <ItemListSection {...itemListProps}/>
      }

    </$InYourReturnDiv>
  );
}

const shouldHideGettingSection = (returnDetails: ReturnDetailsCardProps) => {
  // Separating for readability
  if (returnDetails.returnStatus === ReturnStatus.expired) {
    return true;
  }

  return shouldHideRefundSummary(returnDetails) && (!returnDetails.returnDetails || returnDetails.index === undefined ||
    shouldHideItemListSection(returnDetails.returnDetails[returnDetails.index].exchanges));
}

const shouldHideItemListSection = (items : Array<any>) => {
  return !(items && items.length > 0)
}



const shouldHideRefundSummary = (returnDetails: ReturnDetailsCardProps) => {
  if (!returnDetails || !returnDetails.returnDetails || returnDetails.index === undefined) {
    return true;
  }

  const cardDetails = returnDetails.returnDetails[returnDetails.index];


  if (cardDetails.distributions && cardDetails.distributions?.some((distribution) => containsDigit(distribution.value))) {
    return false;
  }

  if (cardDetails.adjustments && cardDetails.adjustments?.some((adjustment) => containsDigit(adjustment.value))) {
    return false;
  }

  if (cardDetails.total && containsDigit(cardDetails.total)) {
    return false;
  }
  return !(cardDetails.subtotal && containsDigit(cardDetails.subtotal));
}

// This message displays a combination of the exchange message, preview info message, and partial returns message
const ReturnDetailsInfoMessage = (returnDetails: ReturnDetailsCardProps) => {
  const infoMessageText = getInfoMessageText(returnDetails);
  if (!infoMessageText) {
    return <></>
  }

  const onInfoMessageClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStatusPageActions.returnDetailsInfoMessageClicked
    })
  }

  return (
    <$InfoMessageContainer data-cy={DataCyStrings.returnDetailsInfoMessage} onClick={onInfoMessageClick}>
      <InfoMessage message={infoMessageText} />
    </$InfoMessageContainer>
  )
}

const GettingSection = (returnDetails: ReturnDetailsCardProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();
  const [isGettingExpanded, setIsGettingExpanded] = useState(returnDetails?.returnStatus !== ReturnStatus.started && returnDetails?.returnStatus !== ReturnStatus.droppedOff && returnDetails?.returnStatus !== ReturnStatus.expired);

  const itemListProps = {
    isGettingSection: true,
    ... returnDetails
  }

  if (shouldHideGettingSection(returnDetails)) {
    return <></>
  }

  const onShowMoreLessClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStatusPageActions.returnDetailsGettingSectionShowMoreLessClicked
    })
  }

  return (
    <$GettingDiv data-cy={DataCyStrings.gettingDiv}>
      <$GettingHeader>
        <$HeaderText>
          <h2 className="whatYouAreGetting">{t("whatYoureGetting")}</h2>
        </$HeaderText>
        <$ShowMoreLessText {...handleEnterKeyPressOnClickHandlers(() => {
                              setIsGettingExpanded(!isGettingExpanded);
                              onShowMoreLessClick();
                            })}
                           data-cy={DataCyStrings.moreLessButtonGetting}
        >
          {isGettingExpanded ? t("showLess") : t("showMore")}
          <SVG name={isGettingExpanded ? "up" : "down"} />
        </$ShowMoreLessText>
      </$GettingHeader>


      {
        isGettingExpanded && <ItemListSection {...itemListProps} data-cy={DataCyStrings.gettingItemDetails}/>
      }

      {
        isGettingExpanded && !shouldHideRefundSummary(returnDetails) && <RefundSummary {...returnDetails} />
      }
    </$GettingDiv>
  );
};


const getInfoMessageText = (returnDetails: ReturnDetailsCardProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();
  let infoMessageText = "";

  // We only want to show the partial return message for the first return details card
  if (returnDetails.returnStatus === ReturnStatus.partial && returnDetails.index !== undefined && returnDetails.index === 0){
    return t("partialReturnMessage")
  }

  if (returnDetails.returnStatus !== ReturnStatus.started && returnDetails.returnStatus !== ReturnStatus.droppedOff){
    return "";
  }

  if (returnDetails.returnDetails && returnDetails.index !== undefined && returnDetails.returnDetails[returnDetails.index].exchanges && returnDetails.returnDetails[returnDetails.index].exchanges.length > 0) {
    infoMessageText += t("exchangesDefaultMessage");
  }

  if (returnDetails.previewInfoMessage) {
    infoMessageText = infoMessageText == "" ? returnDetails.previewInfoMessage : `${infoMessageText} ${returnDetails.previewInfoMessage}`;
  }

  return infoMessageText;
}

const ItemListSection = (returnDetails : ItemListSectionProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();

  if (!returnDetails || returnDetails.index === undefined || !returnDetails.returnDetails){
    return <></>
  }

  if (returnDetails.isGettingSection && (!returnDetails.returnDetails[returnDetails.index].exchanges || returnDetails.returnDetails[returnDetails.index].exchanges?.length === 0)) {
    return <></>
  }

  const items = returnDetails.isGettingSection ? returnDetails.returnDetails[returnDetails.index].exchanges : returnDetails.returnDetails[returnDetails.index].instances

  if (!items || shouldHideItemListSection(items)) {
    return <></>
  }

  const priceDisplay =  "inline";
  const thumbnailClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStatusPageActions.returnDetailsProductImageClicked
    })
  }
  const returnType = returnDetails.isGettingSection ? "exchange" : "return"

  return (
    <$ItemDetailsContainer key={returnType} data-cy={returnDetails.isGettingSection ? DataCyStrings.gettingItemList : DataCyStrings.inYourReturnItemList}>
      {
        returnDetails.isGettingSection &&
        <div data-cy={`${DataCyStrings.returnType}-${returnType}`}>
          <h3 className="gettingExchange">{t("exchange")}</h3>
        </div>
      }
      {
        !returnDetails.isGettingSection &&
        <$ScreenReaderOnlyComponent as="h3">{t("returning")}</$ScreenReaderOnlyComponent>
      }
      { items && items.map((item) => {
        return <ItemDetails customStyles={$ReturnStatusItemDetails} key={item.id} item={item} showThumbnail runtime={AppRuntimes.returnPortal} priceDisplay={priceDisplay} onClickForThumbnail={thumbnailClick} hidePrice={!checkIfItemHasValidPrice(item)}/>;
      })}
    </$ItemDetailsContainer>
  );
}
