import React from "react";

import getTranslator from "../../../../../utility/getTranslator";

import { ReturnStatusComposite } from "../../../index";
import { isEmpty } from "lodash";
import { $ReturnTotal } from "../../../../Preview/styles";
import { DataCyStrings } from "../../../../../types/DataCyStrings";
import { ProcessingFeeStatus } from "../../../../Preview";
import { $Heading, $RefundSummary, $ReturnShoppingInfoMessageContainer, $Row, $SubHeading } from "./styles";
import { ReturnStatus } from "../../../../../types/ReturnStatus";
import { InfoMessage } from "../../../../../components/AlertMessage";


interface ReturnDetailsCardProps extends ReturnStatusComposite {
  index?: number;
}

// TODO export this into a shared constant between preview and this component
const processingFeeKey = "Processing fee";


export const RefundSummary = (returnDetailsCardProps: ReturnDetailsCardProps) => {
  const { t } = getTranslator("ReturnDetailsCard")();

  const generateDataCyString = (str: string) => {
    return `${str.toLowerCase().replace(" ", "-")}-amount` as DataCyStrings;
  }

  const returnDetails = returnDetailsCardProps.returnDetails;
  const index = returnDetailsCardProps.index;

  const renderTallies = () => {
    if (!returnDetails || index == undefined || returnDetails.length <= index) {
      return <></>;
    }

    const numItemsRefunding = returnDetails[index].instances && returnDetails[index].exchanges ? returnDetails[index].instances.length - returnDetails[index].exchanges.length : 0;

    return (
      <$RefundSummary data-cy={DataCyStrings.refundSummary}>
        <$Heading><h3 className="refundSummary">{t("refundSummary")}</h3></$Heading>
        <$ReturnTotal className="return-portal">
          <$Row>
            <div>{numItemsRefunding == 1 ? t("subtotalOneItem") : t("subtotalMultipleItems", {
              numItems: numItemsRefunding
            })}</div>
            <div data-cy={DataCyStrings.subtotalAmount}>{returnDetails[index].subtotal}</div>
          </$Row>
          {renderAdjustments()}
          {returnDetails[index].total && (
            <$Row className="highlighted">
              <div>
                <b>{t("total")}</b>
              </div>
              <div data-cy={DataCyStrings.totalAmount}>{returnDetails[index].total}</div>
            </$Row>
          )}
          {renderReturnShopping(returnDetailsCardProps)}
          {renderDistributions()}
        </$ReturnTotal>
      </$RefundSummary>
    );
  }

  const shouldRenderReturnShoppingMessage = (returnDetailsCardProps : ReturnDetailsCardProps) => {
    /*
      conditions are:
      - the return has started
      - the processing fee status is waived and displayed
      - the return is a return shopping order that has not been paid
      - there is a distribution that has a processing fee (can be TBD)
    */
    return (returnDetailsCardProps.returnStatus === ReturnStatus.started || returnDetailsCardProps.returnStatus === ReturnStatus.droppedOff) &&
      (returnDetailsCardProps.processingFeeStatus === ProcessingFeeStatus.WaivedDisplay || returnDetailsCardProps.processingFeeStatus === ProcessingFeeStatus.WaivedReadonlyDisplay) &&
      !returnDetailsCardProps.isDraftOrderPaid && returnDetailsCardProps.returnShoppingItems && returnDetailsCardProps.returnShoppingItems.length > 0 &&
      returnDetails && index !== undefined && returnDetails[index].adjustments?.some(adjustment => adjustment.key == processingFeeKey);
  }

  const renderReturnShopping = (returnDetailsCardProps : ReturnDetailsCardProps) => {
    if (!shouldRenderReturnShoppingMessage(returnDetailsCardProps)){
      return <></>
    }

    return (
      <$ReturnShoppingInfoMessageContainer data-cy={DataCyStrings.returnShoppingInfoMessage}>
        <InfoMessage color={"#433B03"} backgroundColor={"var(--soft-yellow)"} message={t("returnShoppingInfoMessage")} />
      </$ReturnShoppingInfoMessageContainer>
    )
  }

  const renderAdjustments = () => {
    if (!returnDetails || index == undefined || returnDetails.length <= index) {
      return;
    }

    const currentAdjustments = returnDetails[index].adjustments
    if(!currentAdjustments) {
      return
    }

    // Processing fee needs to be the last item displayed in adjustments. Adjustments object is converted
    // to an array and initially processing fee is placed first, then order is reversed.
    const adjustments: [string, any][] = [];
    for (let i = 0; i < currentAdjustments.length; i++) {
      const k = currentAdjustments[i].key;

      if (k === processingFeeKey) {
        adjustments.unshift([k, currentAdjustments[i].value]);
      } else {
        adjustments.push([k, currentAdjustments[i].value]);
      }
    }

    adjustments.reverse();
    return (
      <>
        {adjustments.map(([key, value]) => {
          let lineThrough = false;
          const renamedProcessingKey = t("processingFee");
          // processing fee statuses 1 & 3 are meant to display waived fees with a line through.

          if (key === processingFeeKey && (returnDetailsCardProps.processingFeeStatus === ProcessingFeeStatus.WaivedDisplay || returnDetailsCardProps.processingFeeStatus === ProcessingFeeStatus.WaivedReadonlyDisplay)) {
            lineThrough = true;
          }

          return (
            <$Row key={key} className={(lineThrough ? "line-through" : "")}>
              <div>
                { key === processingFeeKey ? renamedProcessingKey : key}
              </div>
              <div data-cy={generateDataCyString(key)}>
                {value}
              </div>
            </$Row>
          );
        })
        }
      </>
    );
  };

  const renderDistributions = () => {
    if (!returnDetails || index == undefined || returnDetails.length <= index) {
      return;
    }

    const currentDistributions = returnDetails[index].distributions
    if(!currentDistributions) {
      return
    }

    const distributions: [string, any][] = [];

    // convert distributions into array
    for (let i = 0; i < currentDistributions.length; i++){
      distributions.push([currentDistributions[i].key, currentDistributions[i].value]);
    }

    return(
      <>
        {!isEmpty(returnDetails[index].distributions) && (
          <$SubHeading>
            <h3 className="refundTo">{(returnDetailsCardProps.returnStatus == ReturnStatus.started || returnDetailsCardProps.returnStatus == ReturnStatus.droppedOff) ? t("refundingTo") : t("refundedTo")}</h3>
          </$SubHeading>
        )}
        { !isEmpty(returnDetails[index].distributions) &&
          distributions.map(([key, value], index) => {

            return (
              <$Row key={key} data-cy={DataCyStrings.distributionRow} className={index == 0 ? "first-distribution-row" : ""}>
                <div>{key}</div>
                <div data-cy={key ? generateDataCyString(key) : DataCyStrings.originalPaymentAmount}>
                  {value}
                </div>
              </$Row>
            );
          })
        }
      </>
    );
  }

  if (!returnDetails || index == undefined || returnDetails.length <= index) {
    return <></>;
  }
  return (
    renderTallies()
  );
};
