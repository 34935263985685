export enum FeatureStudyIDs {
  returnExpMsgDateOfExp = "08081d0d-4b5b-4387-a94a-285cc746f2a1",
  returnExpMsgDaysToComplete = "a68e530c-c222-4c9f-bda8-601dffb7eeea",
  // distance vs preferred location display ranking
  locationDisplayByDistance = "50fd559e-2886-42fc-a597-7a5e4419d619",
  locationDisplayByPreferredRanking =  "f249f85d-f78c-44de-9a55-c4048ad8aa5c",
  // configured vs experiment location display ranking
  locationDisplayByConfiguration = "306b8736-e35c-456b-b0d3-dce3a9fb602c",
  locationDisplayByExperiment =  "c05b978a-f8cd-4779-83e0-d899e18c61d3",
  // return status AB testing
  // returnStatusRedirectOn -> phase 1/2/3
  // returnStatusRedirectOff -> existing confirmation page
  returnStatusRedirectOn = "06a12d33-263d-4a12-bae1-7c8710e68031",
  returnStatusRedirectOff = "85ef64d2-79b2-45cd-9b95-bb554c5f23d7",
}
